<template>
  <div class="home">
	  <Header></Header>
       <div class="update-record-main-box">
			<div class="product01-box">
				<div class="top-p">
                    <div class="big-tit">#{{searchText}}#</div>
                    <div class="select-num">共计 {{allMsgNum}} 条相关结果</div>
                    <!-- <div class="about-msg">
                        <span class="name">相似词条</span>
                        <p class="btn">YOGA</p>
                        <p class="btn">OTA</p>
                        <p class="btn">Pad Pro</p>
                    </div> -->
				</div>
				<div class="two-nav-box">
					<p class="btn-box" @click="goProv">最新消息</p>
					<p class="shu">|</p>
                    <p class="btn-box active-btn" >更新记录</p>
                    <div class="select-box">
						<div class="left-img">
							<img src="../assets/img/select-btn.png" alt="">
						</div>
						<div class="right-input">
							<input type="text" v-model="searchTextInput" @keyup.enter="selectFn" placeholder="搜索" class="input">
						</div>
					</div>
				</div>
				<div class="product01-content-box">
					<div class="product01-content">
						<div class="left-content" v-if="allMsg.length>0">
							<div class="content-slide" v-for="(item,index) in allMsg" :key="index"  @click="goDetails(item.id)">
								<div class="left-text">
									<div class="top-name">{{item.name}}</div>
									<div class="time-logo">
										<img class="img" src="../assets/img/update-time-logo.png" alt="">
										<p class="time">{{timestampToTime(item.metadataInfo.CUSTOMER_PORTAL_PUBLISH_TIME)}}</p>
									</div>
								</div>
								<div class="right-img">
									<img :src="item.coverPicture" alt="">
								</div>
							</div>
						</div>
                        <div class="left-content" v-else>
							暂无数据
						</div>
						<div class="right-see-more">
							<div class="top-tit">相关咨询</div>
							<div class="see-more-content" v-if="rightMoreArr.records.length>0">
								<a :title="item.name"  v-for="(item,index) in rightMoreArr.records" :key="index"  @click.prevent="goDetails(item.id)" class="more-a">{{item.name}}</a>
	
							</div>
                            <div class="see-more-content" v-else>
								<div class="more-a">暂无数据</div>
							</div>
							<div class="btn-more" v-if="rightMoreArr.totalPage>1" @click="seeMore">换一换</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
  </div>
</template>
<script>
//设置html font-size
document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 38.4 + "px";
window.onresize = () => {
  document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 38.4 + "px";
};
/* 
var blockIdArr={
    335 最新消息Banner
    327 最新消息>内容详情
    362 更新信息>机型图
    334 更新记录>内容详情
    333 内测机型Banner
    366 内侧机型>机型列表
    363 二维码-微信
    364 二维码-微博
    indexBanner:335,
    newMsg:327,
    newsjixing:362,
    update:334,
    neicebanner:333,
    neicejixing:366,
    vxcode:363,
    wbcode:364
}
*/
//接口地址引入
import interfaces from "../utils/baseUrl";
import axios from 'axios'
import Header from "../components/Header"
import Footer from "../components/Footer"
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
       allMsg:[],
       pageNum:1,
       pageSize:8,
       searchText:"",
       rightMoreArr:{
		   records:[]
	   },
       morePageNum:1,
	   morePageSize:5,
	   allMsgNum:0,
	   searchTextInput:''
    }
  },
  beforeMount: function () {
    //获取链接参数
    var query = this.$route.query;
    //设置整体展厅参数
    // // this.cacheKey = query.cacheKey;
	// this.getContent({"id": query.searchText})
	this.searchText=query.searchText
    var selectMsg={
      "blockId": interfaces.blockIdArr.newMsg,
      "pageNum":this.pageNum,
      "pageSize":this.pageSize,
      "searchText":this.searchText,
      "filterConditionList":[]
    }
    this.searchFn(selectMsg);
    //获取右侧
    this.searchMoreFn({
        "blockId": interfaces.blockIdArr.newMsg,
        "pageNum":this.morePageNum,
        "pageSize":this.morePageSize,
        "searchText":"",
        "filterConditionList":[]
    })

  },
  //初始化执行
  created: function () {
    let $this = this;
    // $this.getBaseInfo(335)
  },
  //事件
  methods: {
	  seeMore(){
			var $this = this;
			$this.morePageNum=$this.morePageNum++
		this.searchMoreFn({
				"blockId": interfaces.blockIdArr.newMsg,
				"pageNum":$this.morePageNum,
				"pageSize":$this.morePageSize,
				"searchText":"",
				"filterConditionList":[]
			})
	  },

          //获取导航
   getBaseInfo(id){
        var $this = this;
        var config = {
          method: "get",
          url: `${interfaces.baseUrl}/portal/open/block/public/zui/getBaseInfo/${id}`,
          headers:interfaces.headers(),
        };
  
       axios(config)
          .then(function (response) {
            return response.data
          })
          .catch(function (error) {
            return error
          });
    },
     //搜索内容区块中内容
    searchFn(data){
        var $this = this;
        var config = {
            method: "post",
            url: `${interfaces.baseUrl}/portal/open/block/search`,
            headers:interfaces.postHeaders(),
            data,
            };
    
           axios(config)
            .then(function (response) {
                $this.allMsg=response.data.result.records;
				//console.log($this.allMsg)
				$this.allMsgNum=response.data.result.totalCount
            })
            .catch(function (error) {
                return error
            });
      },
       //获取右侧内容
        searchMoreFn(data){
            var $this = this;
            var config = {
                method: "post",
                url: `${interfaces.baseUrl}/portal/open/block/search`,
                headers:interfaces.postHeaders(),
                data,
                };
        
            axios(config)
                .then(function (response) {
                       $this.rightMoreArr=response.data.result;
                })
                .catch(function (error) {
                    return error
                });
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '年';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '月';
            var D = date.getDate() + '日';
            return Y+M+D;
        },
        selectFn(){
            var selectMsg={
                "blockId": interfaces.blockIdArr.newMsg,
                "pageNum":this.pageNum,
                "pageSize":this.pageSize,
                "searchText":this.searchTextInput,
                "filterConditionList":[]
			}
			this.searchText=this.searchTextInput
            this.searchFn(selectMsg)
        },
         goDetails(id){
          //console.log(id);
          this.$router.push({
            name: 'newsdetails',
            query:{
              id:id
            }
          })
		},
		 goProv(id){
          this.$router.push({
            name: 'newMessage',
          })
        },
  },
};
</script>
<style lang="less" scoped>
.footer-box{
	background: #ffffff !important;
}
body{
	overflow: auto;
	background: #f5f5f6;
}
.update-record-main-box{
	width: 100%;
	min-height: calc(100vh - 3rem);
	overflow: hidden;
	position: relative;
	background: #f5f5f6;
}
.update-record-main-box img{
	display: block;
	width: 100%;
}
.update-record-main-box .product01-box{
	width: 100%;
	margin-top: 1.35rem;
	position: relative;
	background: #f5f5f6;
	overflow: hidden;
	padding-bottom: .7rem;
}
.update-record-main-box .product01-box .top-p{
	width: 1200px;
	margin: 0 auto;
    margin-top:46px;
    text-align: left;
}
.update-record-main-box .product01-box .top-p .big-tit{
	font-size: 28px;
	color: #5a596c;
}
.update-record-main-box .product01-box .top-p .select-num{
	color:#acacb5;
	font-size: 14px;
	margin-top:10px;
}
.update-record-main-box .product01-box .top-p .about-msg{
	font-size: 12px;
	color: #acacb5;
	margin-top: 7.5px;
}
.update-record-main-box .product01-box .top-p .about-msg .name{
	display: inline-block;
}
.update-record-main-box .product01-box .top-p .about-msg .btn{
	padding: 3.5px 10px;
	border-radius:17px;
	border: 1px solid #5a596c;
	height: 17px;
	line-height: 17px;
	text-align: center;
	display: inline-block;
	color: #5a596c;
	margin-left: 10px;
}
.update-record-main-box .product01-box .two-nav-box{
	width: 1200px;
	margin:20px auto 0 auto;
	padding-bottom:10px;
	box-sizing: border-box;
	overflow: hidden;
	border-bottom: 1px solid #c6c6cc;
	color: #c7c7c7;
	font-size: 14px;
}
.update-record-main-box .product01-box .two-nav-box .btn-box{
	float: left;
	width:62px;
	cursor: pointer;
}
.update-record-main-box .product01-box .two-nav-box .btn-box:hover{
	color: #0d0c22;
}
.update-record-main-box .product01-box .two-nav-box .active-btn{
	color:#0d0c22;
}
.update-record-main-box .product01-box .two-nav-box .shu{
	float: left;
	padding-right: 10px;
}
.update-record-main-box .product01-box .product01-content-box{
	position: relative;
	width: 100%;
	overflow: hidden;
	margin-top: 25px;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box{
	position: absolute;
	top:0;
	left:475px;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide{
	overflow: hidden;
	margin-top:12.5px;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide .select-box{
	position: relative;
	width:15px;
	height: 15px;
	float: left;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide .select-box .img1{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	display: none;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide-active .select-box .img1{
	display: block;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide .select-box .img2{
	position: absolute;
	top: 50%;
	left: 50%;
	width:5px;
	transform: translate(-50%,-50%);
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide-active .select-box .img2{
	display: none;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide .right-p{
	float: left;
	margin-left: 5px;
	line-height: 16px;
	color:#c6c6cc;
	font-size:12px;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide-active .right-p{
	color: #0f0f22;
}
.update-record-main-box .product01-box .product01-content-box .product01-content{
	width: 1200px;
	margin: 0 auto;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content{
	width:992px;
	float: left;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide{
	width: 100%;
	height:197.6px;
	margin-top:10px;
	background: #ffffff;
	border-radius: 10px;
	border: 1px solid #dedede;
	padding:15px 0 0 20px;
	box-sizing: border-box;
	overflow: hidden;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide:nth-of-type(1){
	margin-top: 0;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide .left-text{
	float: left;
	
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide .left-text .top-name{
	height:30px;
	line-height:30px;
	text-align: center;
	color: #0d0c22;
	font-size: 22px;
	margin-top: 20px;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .center-edition{
	margin-top: 18px;
	color: #5a596c;
    font-size:18px;
    text-align: left;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .time-logo{
	/* display: flex; */
	/* justify-content: center; */
	margin-top: 19px;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .time-logo .img{
	width:15px;
	float: left;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .time-logo .time{
	margin-left:5px;
	float: left;
	line-height:15px;
	font-size: 16px;
	color:#5a596c;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide .right-img{
	float: right;
	margin-right:15px;
	margin-top:20px;
	width:168.5px;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more{
	float: left;
	width:188px;
	margin-left:18px;
	background: #ffffff;
	border-radius: 5px;
	border: 1px solid #dedede;
	overflow: hidden;
	padding:0 10px;
	box-sizing: border-box;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .top-tit{
   width: 100%;
   height:41.5px;
   line-height: 41.5px;
   text-align: center;
   color: #999999;
   font-size: 18px;
   border-bottom: 1px solid #dedede;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .see-more-content{
	margin-top:7.5px;
	padding-bottom: 7.5px;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .see-more-content .more-a{
	display: block;
	width: 100%;
	text-align: center;
	color: #0d0c22;
	font-size: 15px;
    margin-top: 5px;
	cursor: pointer;
	overflow:hidden; /*超出的部分隐藏起来。*/ 
	white-space:nowrap;/*不显示的地方用省略号...代替*/
	text-overflow:ellipsis;/* 支持 IE */
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .see-more-content .more-a:hover{
	 text-decoration:underline;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .btn-more{
	width: 100%;
	text-align: center;
	color:#999999;
	font-size: 16px;
	height:28px;
	line-height:28px;
	border-top: 1px solid #dedede;
}



.update-record-main-box .product01-box .select-box{
	float: right;
	width:158px;
	height:35px;
	line-height:35px;
	background: #ffffff;
	border-radius: 5px;
	border: 1px solid #dfe1e5;
	display: flex;
	box-sizing: border-box;
	align-items: center;
	margin-top: 7.5px;
}
.update-record-main-box .product01-box .select-box .left-img{
    width:14px;
    height:14px;
	margin-left: 10px;
}
.update-record-main-box .product01-box .select-box .left-img img{
    width:14px;
    height:14px;
    display: block;
}
.update-record-main-box .product01-box .select-box .right-input{
	background: none;
	margin-left:7.5px;
	color: #000000;
	font-size: 12px;
}
.update-record-main-box .product01-box .select-box input::-webkit-input-placeholder {
    color:#dfe1e5;
}
.update-record-main-box .product01-box .select-box input::-moz-placeholder { 
    color: #dfe1e5;
}
.update-record-main-box .product01-box .select-box input:-ms-input-placeholder {
    color: #dfe1e5;
}
.update-record-main-box .product01-box .select-box input:-moz-placeholder {
    color: #dfe1e5;
}
</style>